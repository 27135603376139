import numberFomater from "./number_formater";
import { SystemDate } from "./dates";

/**
 * @param {any} value
 * @param {boolean} isLaam
 * @param {string} column
 * @param {boolean|string} hasFormat
 *
 * @returns {string}
 */
export const displayTableValue = (value, isLaam, column, hasFormat = false) => {
	if (
		column == "invoice_date" ||
		column == "end_user_invoice_date" ||
		column == "date" ||
		column == "shipment_date"
	) {
		let dt = new SystemDate(value);
		return dt.display();
	} else if (column == "price" && !isLaam) {
		if (value == null || value == "" || value == "0" || value == 0) {
			return "-";
		}

		const colValue = +value;
		return numberFomater.formatWithCurrency(colValue, isLaam ? "USD" : "REAL");
	} else if (
		(column == "price" ||
			column == "unit_resale" ||
			column == "extended_resale") &&
		isLaam
	) {
		const colValue = +value;
		if (hasFormat) {
			const nf = hasFormat.toLowerCase();
			if (nf == "usd") {
				return numberFomater.formatWithCurrency(colValue, "USD");
			} else {
				return numberFomater.formatWithCurrency(colValue, "REAL");
			}
		} else {
			return numberFomater.formatWithCurrency(colValue, "USD");
		}
	} else {
		return value;
	}
};
