export const camelToSnake = (str) => {
	return str
		.replace(/([a-z])([A-Z])/g, (_, first, second) => `${first}_${second}`)
		.toLowerCase();
};

export const snakeToCamel = (str) => {
	return str.toLowerCase().replace(/(_\w)/g, (match) => {
		return match[1].toUpperCase();
	});
};
