import moment from "moment";

export class SystemDate {
    day;
    month;
    year;
    dateObj;

    constructor(date) {
        if (date === null || date === "") {
            this.day = null;
            this.month = null;
            this.year = null;
            this.dateObj = null;
            return;
        }

        if (date instanceof Date) {
            this.day = date.getDate();
            this.month = date.getMonth() + 1;
            this.year = date.getFullYear();
        } else if (typeof date === "string") {
            if (date.includes("/")) {
                const dateArr = date.split("/");
                this.day = parseInt(dateArr[0]);
                this.month = parseInt(dateArr[1]);
                this.year = parseInt(dateArr[2]);
            } else {
                const dateArr = date.split("-");
                try {
                    var res = dateArr[2].match(/^\d+/);
                    if (res) {
                        this.day = parseInt(res[0]);
                    } else {
                        throw new Error("Invalid date");
                    }
                } catch (e) {
                    this.day = parseInt(dateArr[2].slice(2));
                }
                this.month = parseInt(dateArr[1]);
                this.year = parseInt(dateArr[0]);
            }
        } else {
            throw new Error("Invalid date");
        }

        if (this.day && this.month && this.year) {
            this.dateObj = new Date(this.year, this.month - 1, this.day);
            let isValid =
                this.dateObj.getFullYear() === this.year &&
                this.dateObj.getMonth() === this.month - 1 &&
                this.dateObj.getDate() === this.day;

            if (!isValid) {
                throw new Error("Invalid date");
            }
        }
    }

    display() {
        if (this.dateObj) {
            return moment(this.dateObj).format("DD/MM/YYYY");
        } else {
            return "";
        }
    }

    prepInsert() {
        if (this.dateObj) {
            return moment(this.dateObj).format("YYYY-MM-DD") + " 03:00:00";
        } else {
            return null;
        }
    }
}

