<template>
	<div>
		<f-default-header title="POS" :subtitle="$t('pages.pos.view.subtitle')" />

		<div class="errors-list" v-if="validationErrors.length > 0">
			<div
				class="error-list-item"
				v-for="(error, index) in validationErrors"
				:key="index">
				{{ $t(`pages.pos.preview.error.${error}`) }}
			</div>
		</div>
		<!-- {{ sortedProducts }} -->
		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>
						{{ $t("pages.pos.preview.dataAnalyzed") }}
					</f-card-title>
				</v-col>
			</v-row>
			<v-row :key="refreshList">
				<v-col cols="12">
					<div id="preview_table_pos_wrapper">
						<table id="preview_table_pos">
							<thead>
								<tr>
									<th>*</th>
									<th
										v-for="(item, index) in columnOrder"
										:key="index"
										style="padding-right: 20px">
										{{ $t(`pages.pos.tableHeaders.${distRegion}.${item}`) }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, rowIndex) in sortedProducts" :key="rowIndex">
									<td>{{ rowIndex + 1 }}</td>
									<td v-for="(col, colIndex) in row" :key="colIndex">
										<span v-if="col && col.info && col.info.error">
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<span
														v-bind="attrs"
														v-on="on"
														class="withValidationError">
														{{ $t(`validation.${col.info.errorMessage}`) }}
													</span>
												</template>
												<span>{{ col.value }}</span>
											</v-tooltip>
											<br v-if="col && col.value" />
										</span>
										<span v-if="col && col.value">
											{{
												displayTableValue(
													col.value,
													isLaam,
													camelToSnake(columnOrder[colIndex]),
													getCurrency(columnOrder, row)
												)
											}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="totals.length == 1">
				<v-col cols="12" class="totalValue">
					{{ $t("labels.totalValue") }}:
					<span>{{ displayTableValue(totals[0].total, isLaam, "price") }}</span>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12" class="totalValue">
					{{ $t("labels.totalValue") }}
					<div v-for="(item, index) in totals" :key="index">
						{{ item.date }}:
						<span>{{ displayTableValue(item.total, isLaam, "price") }}</span>
					</div>
				</v-col>
			</v-row>
		</f-card>

		<div v-if="hasUpdates">
			<f-card-spacer />
			<f-card>
				{{ $t("pages.pos.preview.hasUpdate") }}
				<br />
				<br />
				{{ $t("pages.pos.preview.whatDo") }}
				<br />
				<br />
				<v-radio-group v-model="replaceAction">
					<v-radio value="s" :label="$t('labels.change')" />
					<v-radio value="i" :label="$t('labels.ignore')" />
				</v-radio-group>
			</f-card>
		</div>

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnCancel") }}
			</v-btn>
			<v-btn
				v-if="validationErrors.length == 0"
				color="primary"
				elevation="0"
				large
				:disabled="saveDisable"
				@click="save">
				{{ $t("labels.btnFinish") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import numberFomater from "../../helpers/number_formater";
import dateFormater from "../../helpers/date_formater";
import { snakeToCamel, camelToSnake } from "../../helpers/string_utils";
import { displayTableValue } from "../../helpers/table_utils";

export default {
	data: () => ({
		refreshList: 0,
		hasUpdates: false,
		currentErrors: [],
		products: [],
	}),
	computed: {
		validationErrors() {
			if (this.$store.state.pos.view.extras.validationErrors) {
				return this.$store.state.pos.view.extras.validationErrors;
			} else {
				return [];
			}
		},
		replaceAction: {
			get() {
				return this.$store.state.pos.view.replaceAction;
			},
			set(value) {
				this.$store.commit("pos/updateReplaceAction", value);
			},
		},
		saveDisable() {
			if (this.hasUpdates && !this.replaceAction) {
				return true;
			}
			return false;
		},
		totals() {
			const viewTotals = this.$store.state.pos.view.totals;
			var stringDate,
				formatedValue,
				month,
				totalsArr = [];
			for (var date in viewTotals) {
				month = dateFormater.monthNumberToString(date.slice(0, 2));
				stringDate = `${month}/${date.slice(2)}`;
				formatedValue = viewTotals[date];

				if (viewTotals[date]) {
					formatedValue = viewTotals[date];
				}
				totalsArr.push({
					date: stringDate,
					total: formatedValue,
					ref: date,
				});
			}
			return totalsArr.sort((a, b) => {
				if (a.ref < b.ref) return -1;
				if (a.ref > b.ref) return 1;
				return 0;
			});
		},
		distRegion() {
			return this.$store.state.distributor.view.region === "laam"
				? "laam"
				: "br";
		},
		isLaam() {
			return this.distRegion === "laam";
		},
		columnOrder() {
			try {
				if (this.distRegion !== "laam") {
					return [
						"partNumber",
						"activityCnae",
						"quantity",
						"price",
						"shipmentDate",
						"provider",
						"endUser",
						"city",
						"state",
						"distributorName",
						"distributorCnpj",
					];
				} else {
					return [
						"partNumber",
						"quantity",
						"price",
						"unitResale",
						"extendedResale",
						"branchId",
						"city",
						"state",
						"country",
						"postalCode",
						"invoiceDate",
						"invoiceNumber",
						"currencyCode",
						"endUser",
						"endUserNumber",
						"endUserOrder",
						"endUserInvoiceDate",
						"endCountryCode",
						"endUserVerticalMarket",
						"resellerName",
						"distyCountryCode",
						"flukeModelNoun",
						"sicCode",
					];
				}
			} catch (e) {
				return [];
			}
		},
		sortedProducts() {
			return this.products.map((row) => {
				return this.columnOrder.map((index) => row[index]);
			});
		},
	},
	created() {
		this.getData();
	},
	methods: {
		getCurrency(columns, row) {
			let index = columns.indexOf("currency_code");
			if (index == -1) {
				return false;
			}

			return row[index];
		},
		async getData() {
			await this.$store.dispatch("distributor/refresh");
			this.products = this.$store.state.pos.view.products;
		},
		snakeToCamel(value) {
			return snakeToCamel(value);
		},
		camelToSnake(value) {
			return camelToSnake(value);
		},
		displayTableValue(value, isLaam, column, hasFormat = false) {
			return displayTableValue(value, isLaam, column, hasFormat);
		},
		async checkPreviousData() {
			const result = await this.$store.dispatch("pos/checkPreviousData");
			var hasOld;

			this.$store.state.pos.view.replaceList = [];
			this.products.forEach((item) => {
				hasOld = result.body.find((it) => it.id == item.id);
				if (hasOld) {
					item.hasPreviousValue = true;
					item.oldPrice = hasOld.price;
					item.oldValue = numberFomater.money(hasOld.price, {
						decimalLength: 2,
					});
					item.oldTotalValue = numberFomater.money(
						hasOld.price * item.quantity,
						{ decimalLength: 2 }
					);

					this.$store.state.pos.view.replaceList.push(item.id);
				}
			});
			if (result.body.length) this.hasUpdates = true;
			// console.log( this.products )
			this.refreshList++;
			//hasPreviousValue
		},
		async save() {
			const result = await this.$store.dispatch("pos/save", false);

			if (
				result.status == false &&
				result.message == "this_date_exists_alread"
			) {
				var response = await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.error"),
					content: this.$t("alerts.posPreviewAlreadyExists"),
					accept: this.$t("labels.replace"),
					cancel: this.$t("labels.cancel"),
				});
				if (response) {
					await this.$store.dispatch("pos/save", true);
				}
			}

			this.$router.push({
				name: "pos-list",
			});
		},
		back() {
			this.$router.push({
				name: "pos-upload",
			});
		},
	},
};
</script>

<style lang="scss" scoped>
#preview_table_pos_wrapper {
	overflow: auto;
	max-height: 400px;
	max-width: 100%;
}
#preview_table_pos {
	/* border-collapse: collapse; */
	border-spacing: 4px;
	width: 100%;

	th {
		text-align: left;
	}

	th,
	td {
		padding: 2px 10px;
		white-space: nowrap;
	}

	thead tr,
	tr:nth-child(even) {
		background-color: #f5f5f5;
	}

	.currency {
		text-align: right;
	}
}
.totalValue {
	text-align: right;

	span {
		font-weight: bold;
		padding-left: 10px;
		font-size: 1.2em;
	}
}
.hasOld {
	color: red;
}

.errors-list {
	margin-bottom: 14px;

	.error-list-item {
		background-color: #be0000;
		color: #fff;
		padding: 4px 20px;
		border-radius: 5px;
		margin-bottom: 4px;
	}
}

.withValidationError {
	display: inline-block;
	width: 100%;
	background-color: #be0000;
	color: #fff;
}
</style>
